
    import { mapState, mapGetters } from 'vuex';
    import LazyHydrate from 'vue-lazy-hydration';
    import AppHeader from '@/components/Partials/AppHeader';
    import HtmlHeader from '@/components/Partials/HtmlHeader';
    import Sidebar from '@/components/Sidebar/Sidebar';

    export default {
        name: 'Discovery',

        components: {
            AppHeader,
            LazyHydrate,
            Sidebar,
            HeaderLoggedIn: () => import(/* webpackChunkName: "HeaderLoggedIn" */'@/components/Partials/Headers/HeaderLoggedIn'),
            HeaderNonLoggedIn: () => import(/* webpackChunkName: "HeaderNonLoggedIn" */'@/components/Partials/Headers/HeaderNonLoggedIn'),
            MobileMenu: () => import(/* webpackChunkName: "MobileMenu" */'@/components/Partials/MobileMenu'),
            AppFooter: () => import(/* webpackChunkName: "C1Footer" */'@/components/Partials/Footer'),
        },

        mixins: [HtmlHeader],

        data() {
            return {
                contentEl: '',
            };
        },

        head() {
            return this.head;
        },

        computed: {
            ...mapState({
                $_me: state => state.me.user,
                $_appInfo: state => state.appInfo,
            }),

            ...mapGetters('me', {
                $_defaultResume: 'defaultResume',
            }),

            contentClass() {
                return [
                    'content',
                    this.$_me.id ? 'logged-in' : 'not-logged-in',
                ];
            },
        },

        mounted() {
            this.contentEl = this.$refs.content;
        },
    };
