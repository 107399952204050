export default {
    STORE_JOB_TEASER(state, data) {
        state.jobs = data;
    },

    CONCAT_JOB_TEASER(state, data) {
        state.jobs = state.jobs.concat(data);
    },

    STORE_JOB_TEASER_PAGE(state, data) {
        state.page = data;
    },
};
