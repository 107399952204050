import { extend } from 'vee-validate';
// eslint-disable-next-line
import { required, email, min, max, min_value as minValue, required_if as requiredIf, regex, numeric, length, } from 'vee-validate/dist/rules';
import { messages as messageEn } from 'vee-validate/dist/locale/en.json';

extend('regex', regex);

extend('object', {
    validate(value, args) {
        if (typeof value !== 'object' || !value[args.key]) {
            return false;
        }

        return value[args.key] !== '';
    },
    params: ['key'],
    message: 'The {_field_} is required',
});

extend('occupation', {
    validate(value) {
        return !value.occupations || !Array.isArray(value.occupations) || !!value.occupations.length;
    },
    message: '{_field_} is required',
});

extend('required', {
    ...required,
    message: '{_field_} is required',
});

extend('requiredIf', {
    ...required,
    message: messageEn.required_if,
});

extend('email', {
    ...email,
    message: messageEn.email,
});

extend('min', {
    ...min,
    message: '{_field_} must be at least {length} characters',
});

extend('max', {
    ...max,
    message: messageEn.max,
});

extend('min_value', {
    ...minValue,
    message: messageEn.min_value,
});

extend('numeric', {
    ...numeric,
    message: messageEn.numeric,
});

extend('length', {
    ...length,
    message: messageEn.length,
});

extend('no_tag', {
    validate(value, args) {
        let inputValue = value;

        if (typeof value === 'object' && value[args[0]]) {
            inputValue = value[args[0]];
        }

        return /^[^<><|>]+$/.test(inputValue);
    },
    message: '< or > characters are not allowed',
});
