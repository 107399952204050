import has from 'lodash/has';
import orderBy from 'lodash/orderBy';

export default {
    attributeOptionsSkills(state) {
        return state.attributeOptions.filter(o => o.attribute_code === 'skills');
    },

    attributeOptionsPerks(state) {
        return state.attributeOptions.filter(o => o.attribute_code === 'perks');
    },

    attributeOptionsCertifications(state) {
        return state.attributeOptions.filter(o => o.attribute_code === 'certifications');
    },

    attributeOptionsIndustry(state) {
        const attributesIndustry = state.attributeOptions.filter(o => o.attribute_code === 'industry');
        return attributesIndustry.sort((a, b) => a.value.localeCompare(b.value));
    },

    attributeOptionsEducation(state) {
        return state.education;
    },

    attributeOptionsWorkExperience(state) {
        return orderBy(state.workExperience, ['is_current', 'end_date'], ['desc', 'desc']);
    },

    avatar(state) {
        return has(state.user, 'avatar.src') ? state.user.avatar : {};
    },

    defaultResume(state) {
        return state.resumes.find(o => o.is_default) || {};
    },

    discoveryNavCount(state) {
        return state.discoveryNav;
    },

    isOnboarded(state) {
        const user = state.user;
        const scoringData = state.scoringData;
        const desiredOccupations = state.desiredOccupations;

        return user.id && user.profile_status === 'onboarded' &&
            (scoringData.occupations && scoringData.occupations.length) &&
            (scoringData.skills && scoringData.skills.length) &&
            (scoringData.work_locations && scoringData.work_locations.length) &&
            (desiredOccupations && desiredOccupations.length);
    },

    getProfileSections(state) {
        const profileSections = JSON.parse(JSON.stringify(state.profileSections));
        return profileSections.filter((profileSection) => {
            const profileTask = state.profileTasks.find(o => o.section_code === profileSection.section_code);
            if (profileTask && profileTask.task_type !== 'onetime') {
                profileSection.status = profileTask.status;
                profileSection.profileTask = profileTask;
            } else {
                profileSection.status = 'completed';
                profileSection.profileTask = profileTask;
            }
            return profileSection;
        });
    },

    getSortedProfileSections(state, getters) {
        const profileTasks = getters.getProfileSections.filter(i => i.task_order);
        return profileTasks.sort((a, b) => a.task_order - b.task_order);
    },

    getCompletedTaskCount(state, getters) {
        const profileTasks = getters.getSortedProfileSections.filter(i => i.status === 'completed' || i.status === 'paused');
        return profileTasks.length;
    },

    getTotalTaskCount(state, getters) {
        return getters.getSortedProfileSections.length;
    },

    hasRecentResume(state) {
        const defaultResume = state.resumes.find(o => o.is_default);
        if (!defaultResume) { return false; }

        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
        return new Date(defaultResume.created_at) > threeMonthsAgo;
    },
};
