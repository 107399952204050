import Cookie from 'js-cookie';

export default {
    register(context, input) {
        const url = 'v1/register';
        let data = input;
        if (input.resume_file instanceof File) {
            data = new FormData();

            Object.entries(input).forEach((o) => {
                data.append(o[0], o[1]);
            });
        }

        return this.$axios.$post(url, data);
    },

    login(context, data) {
        const url = 'v1/login';
        return this.$axios.$post(url, data);
    },

    socialLogin(context, data) {
        const url = 'v1/login/social';
        return this.$axios.$post(url, data);
    },

    forgotPassword(context, data) {
        const url = 'v1/forgot-password';
        return this.$axios.$post(url, data);
    },

    resetPassword(context, data) {
        const url = 'v1/reset-password';
        return this.$axios.$put(url, data);
    },

    resetEmail(context, token) {
        const url = `v1/reset-email?token=${token}`;
        return this.$axios.$put(url);
    },

    logout({ commit }) {
        Cookie.remove(process.env.COOKIE_TOKEN_NAME);

        commit('me/CLEAR_USER', null, { root: true });
        commit('appInfo/CLOSE_SIDEBAR', null, { root: true });
    },

    verifyDomain(context, domain) {
        const url = `v1/verify-domain?domain=${domain}`;
        return this.$axios.$get(url);
    },

    debounceValidateEmail(context, data) {
        const url = `v1/debounce/validate-email?email=${data.email}`;
        return this.$axios.$get(url);
    },

    sendOTC(context, email) {
        const url = 'v1/send-otc';
        const payload = { email };
        return this.$axios.$post(url, payload);
    },

    loginOtc(context, payload) {
        const url = 'v1/login-otc';
        return this.$axios.$post(url, payload);
    },
};
